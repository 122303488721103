import 'slick-carousel';

$(function(){
	$('header').addClass('change-color');
    $('.slider').slick({
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 2500,
    infinite: true,
    fade: true,
    centerMode: true,
    slidesToShow:1,
    slidesToScroll:3,
    pauseOnFocus: false,
    pauseOnHover: false,
	});
  $('.head-menu-sp').on('click',function(){
    $('.head-menu-sp-menu').fadeToggle();
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $('body').css('overflow','auto');
    }else{
      $(this).addClass('active');
      $('body').css('overflow','hidden');
    }
  });
});
